<template>
  <div class="page">
    <Navbar title="用户登录" />
    <van-form @submit="login" class="form">
      <van-row class="title">
        <van-col span="24">用户登录</van-col>
      </van-row>
      <van-cell-group>
        <van-field
          v-model="state.phone"
          label="手机号"
          type="tel"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        >
          <template #button>
            <van-button
              size="mini"
              v-if="sendBtnTime > 0"
              :disabled="true"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码({{ sendBtnTime }})</van-button
            >
            <van-button
              size="mini"
              v-if="sendBtnTime <= 0"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码</van-button
            >
          </template></van-field
        >
        <van-field
          v-model="state.vcode"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
        </van-field>
        <van-row>
          <van-col offset="8" span="8" class="subbtn">
            <van-button
              block
              type="primary"
              :color="COLOR"
              size="small"
              native-type="submit"
            >
              立即登录
            </van-button></van-col
          >
        </van-row>
      </van-cell-group>
    </van-form>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
    <LogVisit ref="logVisit" module-type="OTH" module="OTH_LOGIN" />
  </div>
</template>
<script>
import { reactive } from 'vue'
import { Form, Field, CellGroup } from 'vant'
import Navbar from '../common/Navbar.vue'
import LogVisit from '../common/LogVisit.vue'
export default {
  components: {
    Navbar: Navbar,
    LogVisit: LogVisit,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup
  },
  setup () {
    const state = reactive({
      phone: '',
      vcode: ''
    })
    return {
      state
    }
  },
  data () {
    return {
      sendBtnTime: 0,
      loadingShow: false,
      returnBack: 'N',
      validateState: true,
      upper: '1'
    }
  },
  mounted () {
    var uc = window.sessionStorage.getItem(this.SESSION_SHARER)
    if (uc !== null && uc !== undefined && uc !== '') {
      this.upper = uc
    }
    this.$refs.logVisit.createLog()
  },
  methods: {
    async login () {
      if (this.validateState) {
        this.loadingShow = true
        var pd = { phone: this.state.phone, password: '123123' }
        const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/login', this.$qs.stringify(pd))
        this.loadingShow = false
        if (res.status === '200') {
          window.sessionStorage.setItem(this.SESSION_TOKEN, res.data.token)
          window.sessionStorage.setItem(this.SESSION_USER, res.data.code)
          window.sessionStorage.setItem(this.SESSION_OPERATOR, '1')
          window.sessionStorage.setItem(this.SESSION_SELLER, '50')
          this.returnPage()
        } else {
          this.$dialog.alert({
            title: '用户登录',
            message: res.msg
          }).then(() => {
            // on close
          })
        }
      } else {
        this.$dialog.alert({
          title: '验证码提示',
          message: '请点击发送手机验证码'
        }).then(() => {
          // on close
        })
      }
    },
    async sendValidate () {
      var pd = { phone: this.state.phone }
      var that = this
      const { data: res } = await this.$http.post(this.BMS_URL + '/member/user/sendValidate', this.$qs.stringify(pd))
      if (res.status === '200') {
        that.validateState = true
        that.sendBtnTime = 60
        that.countdown()
      }
    },
    async onlineResult () {
      var pd = { userCode: this.upper, taskCode: '1' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/online/result/createInvite', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.returnPage()
      }
    },
    returnPage () {
      if (this.returnBack === 'N') {
        this.$router.push({ path: '/mde/main/home' })
      } else {
        this.$router.go(-1)
      }
    },
    countdown () {
      var that = this
      setTimeout(function () {
        that.sendBtnTime--
        if (that.sendBtnTime > 0) {
          that.countdown()
        }
      }, 1000)
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 50px 5px;
}
.title {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
